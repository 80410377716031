import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { UsuariosService } from "src/app/shared/service/usuarios.service";
import { Usuarios_historialService } from "src/app/shared/service/usuarios_historial.service";
import Chart from "chart.js/auto";
import { groupBy } from "lodash";

@Component({
  selector: "app-user-detail",
  templateUrl: "./user-detail.component.html",
  styleUrls: ["./user-detail.component.scss"],
})
export class UserDetailComponent implements OnInit, OnDestroy {
  user: any = {};
  leadTypes = ['Calificado', 'Caliente', 'Frío', 'Inbound', 'Outbound', 'Generado por Eventos', 'Referido'];
  selectedLeadType: string = '';
  leadSelectorOpen = false;
  qr: any;
  puntos: any;
  usuarios: any[] = [];
  logs: any[] = [];
  transferencias: any[] = [];
  historialChart: Chart | null = null;
  logsChart: Chart | null = null;
  historialTransferenciasChart: Chart | null = null;
  files: File[] = [];
  archivosSimulados: any[] = [];

  constructor(
    private usuariosService: UsuariosService,
    private usuariosHistorialService: Usuarios_historialService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const userId = localStorage.getItem("selectedUserId");

    if (userId) {
      this.getUsuario(userId);
      this.getUsuarioHistorial(userId);
      this.getUsuarioLogs(userId);
      this.getHistorialTransferencias(userId);
    }
  }

  ngOnDestroy(): void {
    if (this.historialChart) {
      this.historialChart.destroy();
    }
    if (this.logsChart) {
      this.logsChart.destroy();
    }
  }

  // save(): void {
  //   this.usuariosService.updateUser(this.user).subscribe(() => {
  //     // Handle successful update
  //   });
  // }

  goBack(): void {
    this.router.navigate(["/users/list-user"]);
  }

  onFileSelected(event: any) {
    const selectedFiles = event.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      this.files.push(selectedFiles[i]);
    }
  }
  simularSubidaArchivo() {
    const archivoFalso = {
      nombre: 'FacturaA12423.pdf',
      subidoPor: 'Joaquin Barranco',
      subidoEn: 'Jul. 23, 2024',
      tamano: '2.4mb'
    };
    this.archivosSimulados.push(archivoFalso);
  }

  getUsuario(id: any) {
    this.usuariosService.getUserById(id).subscribe(
      (response: any) => {
        // Asignar los valores de la respuesta a la variable user
        this.user = response;
      },
      (error: any) => {
        // Manejar el error si es necesario
        console.error("Error al obtener usuario:", error);
      }
    );

    this.usuariosService.getUserQR(id).subscribe(
      (response: Blob) => {
        // Crear un URL para la imagen del Blob
        const urlCreator = window.URL || window.webkitURL;
        this.qr = urlCreator.createObjectURL(response);
      },
      (error: any) => {
        // Manejar el error si es necesario
        console.error("Error al obtener QR del usuario:", error);
      }
    );

    this.usuariosService.getUserPuntos(id).subscribe(
      (response: any) => {
        // Asignar los puntos del usuario a la variable puntos
        this.puntos = response.puntos;
      },
      (error: any) => {
        // Manejar el error si es necesario
        console.error("Error al obtener puntos del usuario:", error);
      }
    );
  }

  getUsuarioHistorial(userId: string) {
    this.usuariosHistorialService.getUserHistoryById(userId).subscribe(
      (response: any) => {
        // Ordenar por fecha más reciente primero para la tabla
        this.usuarios = response.sort(
          (a, b) => new Date(b.fecha).getTime() - new Date(a.fecha).getTime()
        );
        // Ordenar por fecha más antigua primero para el gráfico
        const sortedForChart = [...response].sort(
          (a, b) => new Date(a.fecha).getTime() - new Date(b.fecha).getTime()
        );
        this.loadHistorialAccionesChart(sortedForChart); // Load chart when data is received
      },
      (error: any) => {
        console.error("Error al obtener historial del usuario:", error);
      }
    );
  }

  getUsuarioLogs(userId: string) {
    this.usuariosHistorialService.getLogsByUserId(userId).subscribe(
      (response: any) => {
        // Ordenar por fecha más reciente primero para la tabla
        this.logs = response.sort(
          (a, b) =>
            new Date(b.fecha_logueo).getTime() -
            new Date(a.fecha_logueo).getTime()
        );
        // Ordenar por fecha más antigua primero para el gráfico
        const sortedForChart = [...response].sort(
          (a, b) =>
            new Date(a.fecha_logueo).getTime() -
            new Date(b.fecha_logueo).getTime()
        );
        this.loadUserLogsChart(sortedForChart); // Load chart when data is received
      },
      (error: any) => {
        console.error("Error al obtener logs del usuario:", error);
      }
    );
  }

  getHistorialTransferencias(userId: string) {
    // Obtener historial de transferencias de puntos
    this.usuariosService.getHistorialTransferencias(userId).subscribe(
      (response: any[]) => {
        this.transferencias = response; // Asignar datos al arreglo de transferencias
        this.loadHistorialTransferenciasChart(response); // Cargar gráfico
      },
      (error: any) => {
        console.error("Error al obtener historial de transferencias:", error);
      }
    );
  }

  loadHistorialAccionesChart(dataOriginal: any[]) {
    const ctx = document.getElementById(
      "historialAccionesChart"
    ) as HTMLCanvasElement;
    if (this.historialChart) {
      this.historialChart.destroy();
    }

    const groupedData = groupBy(dataOriginal, (user) =>
      new Date(user.fecha).toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
      })
    );

    const labels = Object.keys(groupedData).sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );

    const datasets: any[] = [];

    const productNames = [
      ...new Set(dataOriginal.map((user) => user.nombre_producto)),
    ];

    productNames.forEach((productName) => {
      const data = labels.map(
        (label) =>
          groupedData[label].filter(
            (item) => item.nombre_producto === productName
          ).length
      );
      datasets.push({
        label: productName,
        data: data,
        fill: true,
      });
    });

    const data = {
      labels: labels,
      datasets: datasets,
    };

    this.historialChart = new Chart(ctx, {
      type: "bar",
      data: data,
      options: {
        responsive: true,
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: "Fecha",
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: "Cantidad",
            },
          },
        },
      },
    });
  }

  loadUserLogsChart(dataOriginal: any[]) {
    const ctx = document.getElementById("userLogsChart") as HTMLCanvasElement;
    if (this.logsChart) {
      this.logsChart.destroy();
    }

    const groupedLogs = groupBy(dataOriginal, (log) =>
      new Date(log.fecha_logueo).toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
      })
    );

    const labels = Object.keys(groupedLogs).sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );

    const data = {
      labels: labels,
      datasets: [
        {
          label: "Loggeos",
          data: labels.map((label) => groupedLogs[label].length),
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
      ],
    };

    this.logsChart = new Chart(ctx, {
      type: "line",
      data: data,
      options: {
        responsive: true,
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: "Fecha",
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: "Cantidad",
            },
          },
        },
      },
    });
  }

  loadHistorialTransferenciasChart(dataOriginal: any[]) {
    const ctx = document.getElementById(
      "historialTransferenciasChart"
    ) as HTMLCanvasElement;
    if (!ctx) return; // Verificar si el canvas existe

    // Destruir el gráfico anterior si existe
    if (this.historialTransferenciasChart) {
      this.historialTransferenciasChart.destroy();
    }

    // Agrupar los datos por fecha para el gráfico
    const groupedData = groupBy(dataOriginal, (transferencia) =>
      new Date(transferencia.fecha).toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
      })
    );

    // Obtener las etiquetas (fechas) y ordenarlas
    const labels = Object.keys(groupedData).sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );

    // Invertir el orden de las etiquetas y datos
    const reversedLabels = labels.reverse();
    const data = reversedLabels.map((label) =>
      groupedData[label].reduce(
        (acc: number, transferencia: any) => acc + transferencia.puntos,
        0
      )
    );

    // Asignar colores a las barras, diferenciando entre positivos y negativos
    const backgroundColors = data.map((value) =>
      value < 0 ? "rgb(255, 99, 132)" : "rgb(75, 192, 192)"
    );

    // Crear el gráfico
    this.historialTransferenciasChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: reversedLabels,
        datasets: [
          {
            label: "Puntos Transferidos",
            data: data,
            backgroundColor: backgroundColors,
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: "Fecha",
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: "Puntos Transferidos",
            },
          },
        },
      },
    });
  }

  toggleLeadSelector() {
    this.leadSelectorOpen = !this.leadSelectorOpen;
  }

  selectLeadType(type: string, usuario: any) {
    usuario.tags = type;  // Actualiza la etiqueta en el usuario localmente
    
    // Envía la actualización al servidor
    this.updateClientTags(usuario);
    
    this.leadSelectorOpen = false;  // Cierra el dropdown
  }
  

  updateClientTags(updatedUser: any) {
    this.usuariosService.updateUser(updatedUser.id, updatedUser).subscribe(
      response => {
        console.log('Usuario actualizado:', response);
        // Si es necesario, actualiza la lista de usuarios o realiza cualquier acción adicional
      },
      error => {
        console.error('Error al actualizar el usuario:', error);
      }
    );
  }
}
