import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/dashboard/default', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Productos', icon: 'coffee', type: 'sub', active: false, children: [
				{
					title: 'Productos', type: 'link', path: '/products/digital/digital-product-list' 
				},
				{
					title: 'Añadir Productos', type: 'link', path: '/products/digital/digital-add-product'
				},
			]
		},
		{
			title: 'Cupones', icon: 'box', type: 'sub', active: false, children: [
				{
					title: 'Lista Cupones', type: 'link', path: '/coupons/list-coupon' 
				},
				{
					title: 'Añadir Cupones', type: 'link', path: '/coupons/create-coupon'
				},
			]
		},
		{
			title: 'Gestión Clientes', icon: 'users', type: 'sub', active: false, children: [
				{ path: '/users/list-user', title: 'Lista Clientes', type: 'link' },
				{ path: '/users/create-client', title: 'Crear Cliente', type: 'link' },
			]
		},
		{
			title: 'Ventas', icon: 'dollar-sign', type: 'sub', active: false, children: [
				{ path: '/ventas/ventas', title: 'Ventas', type: 'link' }
			]
		},
		{
			title: 'Usuarios', icon: 'user', type: 'sub', active: false, children: [
				{ path: '/workers/list-panel-workers', title: 'Lista de Usuarios', type: 'link' },
				{ path: '/workers/create-worker', title: 'Añadir Usuario', type: 'link' }
			]
		},
		{
			title: 'Presupuestos', icon: 'dollar-sign', type: 'sub', active: false, children: [
				{ path: '/budget/budget', title: 'Presupuesto', type: 'link' },
				{ path: '/budget/newBudget', title: 'Añadir Usuario', type: 'link' }
			]
		},
		 {
			title: 'Administración', icon: 'settings', type: 'sub', active: false, children: [
				{ path: '/administration/tasks', title: 'Tareas', type: 'link'},
				{ path: '/administration/automations', title: 'Automatizaciones', type: 'link'},
				{ path: '/administration/outgoings', title: 'Gastos', type: 'link'},
				{ path: '/administration/income', title: 'Ingresos', type: 'link'},
				{ path: '/administration/budgets', title: 'Presupuestos', type: 'link'},
				{ path: '/administration/permisos', title: 'Permisos', type: 'link'},
				// { path: '/administration/logs', title: 'Logs', type: 'link'},
				{ path: '/administration/registro-actividad', title: 'Logs', type: 'link'}

			]
		}, 
		 {
			title: 'Plataformas', icon: 'link', type: 'sub', active: false, children: [
				{ path: '/integrations', title: 'Integraciones', type: 'link'}
			]
		}, 
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
