<div class="container-fluid">
  <div class="row product-adding">
    <div class="col-xl">
      <div class="card">
        <div class="card-body">
          <div class="digital-add needs-validation">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <div class="form-group">
                    <label class="col-form-label"
                      ><span>*</span>Seleccione una Categoria</label
                    >
                    <select
                      class="custom-select form-select"
                      (change)="onChangeCategoria($event.target.value)"
                      required=""
                      [(ngModel)]="categoriaSeleccionada"
                    >
                      <option value="">--Select--</option>
                      <option
                        *ngFor="let categoria of categorias"
                        [value]="categoria.id"
                      >
                        {{ categoria.nombre }}
                      </option>
                    </select>
                    <div class="form-group">
                      <label class="col-form-label"
                        ><span>*</span>Seleccione un Producto</label
                      >
                      <select
                        class="custom-select form-select"
                        (change)="
                          onChangeProductoSeleccionado($event.target.value)
                        "
                        [(ngModel)]="productoSeleccionado"
                      >
                        <option value="">--Select--</option>
                        <option
                          *ngFor="let producto of productos"
                          [value]="producto.id"
                        >
                          {{ producto.nombre }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label"><span>*</span>Detalles</label>
                    <textarea
                      rows="5"
                      cols="12"
                      [(ngModel)]="detalles"
                    ></textarea>
                  </div>

                  <div class="form-group row">
                    <div class="col-xl-3 col-md-4">
                      <label><span>*</span>Fecha inicio</label>
                    </div>
                    <div class="col-md-7">
                      <div class="input-group input-grp-p">
                        <input
                          class="datepicker-here form-control digits"
                          type="date"
                          ngbDatepicker
                          #f="ngbDatepicker"
                          [(ngModel)]="fecha_inicio"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn calendar"
                            (click)="f.toggle()"
                            type="button"
                          >
                            <i class="fa fa-calendar"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-md-4">
                      <label><span>*</span>Puntos Necesarios</label>
                    </div>
                    <div class="col-md-7">
                      <input
                        class="form-control"
                        type="number"
                        required=""
                        [(ngModel)]="puntos_necesarios"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-12">
                      <div class="checkbox checkbox-primary">
                        <input
                          id="checkbox-primary-2"
                          type="checkbox"
                          [(ngModel)]="activo"
                          data-original-title=""
                          title=""
                        />
                        <label for="checkbox-primary-2">Activar cupon</label>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-4 mt-3">
                      <label for="imageURL"><span>*</span>URL de la imagen:</label>
                    </div>
                    <div class="card-body p-0">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="imageURL"
                          [(ngModel)]="img"
                          (change)="onUrlChange($event)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="pull-right">
              <button
                type="button"
                class="btn btn-primary"
                (click)="addCupon()"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
