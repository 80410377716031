<div class="container-fluid">
  <div class="row mb-3">
    <div class="col-12 text-center">
      <button class="btn btn-primary" (click)="changeMonth(-1)">
        Anterior
      </button>
      <span class="mx-2 fecha">
        {{ monthNames[currentMonth] }} de {{ currentYear }}
      </span>
      <button class="btn btn-primary" (click)="changeMonth(1)">
        Siguiente
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-6 mb-4">
      <div class="card">
        <div class="card-header">
          <h2 class="card-title text-center">Historial de Productos</h2>
        </div>
        <div class="card-body">
          <canvas id="historialProductosChart"></canvas>
        </div>
      </div>
    </div>
    <div class="col-6 mb-4">
      <div class="card">
        <div class="card-header">
          <h2 class="card-title text-center">Historial de Logs</h2>
        </div>
        <div class="card-body">
          <canvas id="historialLogsChart"></canvas>
        </div>
      </div>
    </div>
    <div class="col-6 offset-md-3 mb-4">
      <div class="card">
        <div class="card-header">
          <h2 class="card-title text-center">Membresías</h2>
        </div>
        <div class="card-body">
          <canvas id="membresiasChart" style="height: 200px"></canvas>
        </div>
      </div>
    </div>
  </div>
</div>
