<div class="container-fluid">
    <div class="row product-adding">
        <div class="col-xl">
            <div class="card">
                <div class="card-header">
                    <h5>General</h5>
                    <button class="btn btn-primary me-1 mb-3" style="float: right;"(click)="updateProducto()">Guardar</button>
                </div>                
                <div class="card-body">
                    <div class="digital-add needs-validation">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="col-form-label pt-0"><span>*</span> Nombre</label>
                                    <input class="form-control" type="text" required="" [(ngModel)]="nombre">
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label"><span>*</span>Categorias</label>
                                    <select class="custom-select form-select" (change)="onChangeCategoria($event.target.value)" required="" [(ngModel)]="categoriaSeleccionada">
                                        <option value="">--Select--</option>
                                        <option *ngFor="let cat of categorias" [value]="cat.id">{{ cat.nombre }}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label">Descripcion</label>
                                    <textarea rows="5" cols="12" [(ngModel)]="descripcion"></textarea>
                                </div>
                                <label for="imageURL">URL de la imagen:</label>
                                <div class="card-body p-0">
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="imageURL" [(ngModel)]="imagen" (change)="onUrlChange($event)">
                                    </div>
                                </div>  
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="col-form-label"><span>*</span> Precio</label>
                                    <input class="form-control" type="text" required="" [(ngModel)]="precio">
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label"><span>*</span> Precio oferta</label>
                                    <input class="form-control" type="text" required="" [(ngModel)]="precio_oferta">
                                </div>
                                <div class="form-group row">
                                    <div class="col-xl-3 col-md-4">
                                        <label>Oferta</label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="checkbox checkbox-primary">
                                            <input id="checkbox-primary-2"  type="checkbox" data-original-title="" title="" [(ngModel)]="oferta">
                                            <label for="checkbox-primary-2">Activar oferta</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 
