<div class="container-fluid">
  <div class="card">
    <div class="card-body pt-3">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label>Nombre</label>
            <div class="form-control-plaintext">{{ user.nombre }}</div>
          </div>
          <div class="form-group">
            <label>Apellidos</label>
            <div class="form-control-plaintext">{{ user.apellidos }}</div>
          </div>
          <div class="form-group">
            <label>Email</label>
            <div class="form-control-plaintext">{{ user.email }}</div>
          </div>
          <div class="form-group">
            <label>Telefono</label>
            <div class="form-control-plaintext">{{ user.telefono }}</div>
          </div>
          <div class="form-group">
            <label>Direccion</label>
            <div class="form-control-plaintext">{{ user.direccion }}</div>
          </div>
          <div class="form-group">
            <label>Ciudad</label>
            <div class="form-control-plaintext">{{ user.ciudad }}</div>
          </div>
          <div class="form-group">
            <label>Codigo Postal</label>
            <div class="form-control-plaintext">{{ user.cp }}</div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label>Fecha de Nacimiento</label>
            <div class="form-control-plaintext">
              {{ user.fecha_nacimiento | date : "dd/MM/yyyy" }}
            </div>
          </div>
          <div class="form-group">
            <label>Fecha de Registro</label>
            <div class="form-control-plaintext">
              {{ user.fecha_registro | date : "dd/MM/yyyy" }}
            </div>
          </div>
          <div class="form-group">
            <label>Código QR</label>
            <div class="form-control-plaintext">
              <div *ngIf="qr" class="small-qr">
                <img [src]="qr" alt="QR Code" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Puntos</label>
            <div class="form-control-plaintext">{{ puntos || 0 }}</div>
          </div>
          <div class="form-group lead-type">
            <label>Tipo de Lead</label>
            <div ngbDropdown class="d-inline-block">
              <button class="btn btn-secondary" id="dropdownMenuButton" ngbDropdownToggle>
                {{ user.tags || 'Seleccionar tipo de Lead' }}
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                <button ngbDropdownItem *ngFor="let type of leadTypes" (click)="selectLeadType(type, user)">
                  {{ type }}
                </button>
              </div>
            </div>
          </div>

          <div class="form-group archivos">
            <label>Archivos</label>
            <input type="file" (change)="onFileSelected($event)" hidden #fileInput>
            <button class="btn btn-secondary" (click)="fileInput.click()">Subir archivos</button>
            <div class="uploaded-files">
                <ul *ngIf="files.length > 0">
                    <li *ngFor="let file of files">{{ file.name }}</li>
                </ul>
            </div>
        </div>
        
          
        </div>
      </div>
    </div>

    <!-- Historial de acciones, transferencias y logs -->
    <div class="row mt-4">
      <!-- Historial de acciones -->
      <div class="col-12 col-lg-5">
        <div class="card table-card">
          <div class="card-header p-3">
            <h5>Historial de Acciones</h5>
          </div>
          <div class="card-body p-3">
            <div class="table-responsive">
              <table class="table table-striped">
                <!-- Cabecera de la tabla -->
                <thead>
                  <tr>
                    <th scope="col">Producto</th>
                    <th scope="col">Puntos canjeados</th>
                    <th scope="col">Puntos anteriores</th>
                    <th scope="col">Puntos posteriores</th>
                    <th scope="col">Fecha</th>
                  </tr>
                </thead>
                <!-- Cuerpo de la tabla -->
                <tbody *ngIf="usuarios && usuarios.length > 0">
                  <tr *ngFor="let user of usuarios">
                    <td>{{ user.nombre_producto }}</td>
                    <td
                      [ngClass]="{
                        positive: user.puntos_canjeados > 0,
                        negative: user.puntos_canjeados < 0
                      }"
                    >
                      {{ user.puntos_canjeados }}
                    </td>
                    <td>{{ user.puntos_anteriores }}</td>
                    <td>{{ user.puntos_posteriores }}</td>
                    <td>{{ user.fecha | date : "dd/MM/yyyy" }}</td>
                  </tr>
                </tbody>
                <!-- Manejo de tabla vacía -->
                <tbody *ngIf="!usuarios || usuarios.length === 0">
                  <tr>
                    <td colspan="8" class="text-center">
                      No hay historial de este cliente
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Historial de transferencias de puntos -->
      <div class="col-12 col-lg-4">
        <div class="card table-card">
          <div class="card-header p-3">
            <h5>Historial de Transferencias de Puntos</h5>
          </div>
          <div class="card-body p-3">
            <div class="table-responsive">
              <table class="table table-striped">
                <!-- Cabecera de la tabla -->
                <thead>
                  <tr>
                    <th scope="col">Receptor</th>
                    <th scope="col">Puntos</th>
                    <th scope="col">Fecha</th>
                  </tr>
                </thead>
                <!-- Cuerpo de la tabla -->
                <tbody *ngIf="transferencias && transferencias.length > 0">
                  <tr *ngFor="let transferencia of transferencias">
                    <td>{{ transferencia.nombre_usuario_2 }}</td>
                    <td>{{ transferencia.puntos }}</td>
                    <td>{{ transferencia.fecha | date : "dd/MM/yyyy" }}</td>
                  </tr>
                </tbody>
                <!-- Manejo de tabla vacía -->
                <tbody *ngIf="!transferencias || transferencias.length === 0">
                  <tr>
                    <td colspan="3" class="text-center">
                      No hay historial de transferencias de puntos
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Logs del usuario -->
      <div class="col-12 col-lg-3">
        <div class="card table-card">
          <div class="card-header p-3">
            <h5>Logs</h5>
          </div>
          <div class="card-body p-3">
            <div class="table-responsive">
              <table class="table table-striped">
                <!-- Cabecera de la tabla -->
                <thead>
                  <tr>
                    <th scope="col">Fecha</th>
                    <th scope="col">IP</th>
                    <th scope="col">Protocolo</th>
                  </tr>
                </thead>
                <!-- Cuerpo de la tabla -->
                <tbody *ngIf="logs && logs.length > 0">
                  <tr *ngFor="let log of logs">
                    <td>{{ log.fecha_logueo | date : "dd/MM/yyyy" }}</td>
                    <td>{{ log.ip }}</td>
                    <td>{{ log.protocolo }}</td>
                  </tr>
                </tbody>
                <!-- Manejo de tabla vacía -->
                <tbody *ngIf="!logs || logs.length === 0">
                  <tr>
                    <td colspan="3" class="text-center">
                      No hay logs de este cliente
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Gráficos -->
    <div class="row mt-4">
      <!-- Gráfico Historial de Acciones -->
      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-header p-3">
            <h5>Gráfico Historial de Acciones</h5>
          </div>
          <div class="card-body p-3">
            <canvas id="historialAccionesChart"></canvas>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-header p-3">
            <h5>Gráfico Historial de Acciones</h5>
          </div>
          <div class="card-body p-3">
            <canvas id="ingresosChart"></canvas>
          </div>
        </div>
      </div>

      <!-- Gráfico Historial de Transferencias de Puntos -->
      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-header p-3">
            <h5>Gráfico Historial de Transferencias de Puntos</h5>
          </div>
          <div class="card-body p-3">
            <canvas id="historialTransferenciasChart"></canvas>
          </div>
        </div>
      </div>
      <!-- Gráfico Historial de Logs -->
      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-header p-3">
            <h5>Gráfico Historial de Logs</h5>
          </div>
          <div class="card-body p-3">
            <canvas id="userLogsChart"></canvas>
          </div>
        </div>
    </div>

    <div class="form-group archivos-cliente">
      <label>Archivos</label>
      <button class="btn btn-secondary" (click)="simularSubidaArchivo()">Archivos del cliente</button>
    </div>
    
    <div *ngIf="archivosSimulados.length > 0" class="archivos-subidos">
      <h5>Documentos adjuntos</h5>
      <table class="table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Subido por</th>
            <th>Subido en</th>
            <th>Tamaño</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let archivo of archivosSimulados">
            <td>{{ archivo.nombre }}</td>
            <td>{{ archivo.subidoPor }}</td>
            <td>{{ archivo.subidoEn }}</td>
            <td>{{ archivo.tamano }}</td>
            <td> <a><i class="fa fa-eye" aria-hidden="true"></i></a>&nbsp;
             <a> <i class="fa fa-download" aria-hidden="true"></i></a>
            </td>

          </tr>
        </tbody>
      </table>
    </div>
    
  </div>
</div>
