import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BASE_URL_API } from 'src/environments/environment'; 
import { Router } from '@angular/router';
import { AuthUser } from '../model/auth.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router
    ) { }

  login(body: any): Observable<any> {
    console.log(body);
    return this.http.post<any>(`${BASE_URL_API}/login/user`, body)
      .pipe(
        tap(response => {
          const authUser: AuthUser = {
            token: response.token,
            nombre: response.userDetails.nombre,
            email: response.userDetails.email,
            id: response.userDetails.id,
          };
          console.log(authUser)
          localStorage.setItem("auth_app_token", JSON.stringify(authUser));
          this.router.navigate(["dashboard/default"]);
        }),
        catchError(this.handleError)
      );
  }
  register(data: any): Observable<any> {
    return this.http.post<any>(`${BASE_URL_API}/register`, data)
      .pipe(
        tap(response => console.log('Registro exitoso', response)),
        catchError(this.handleError)
      );
  }

  logout(): void {
    localStorage.removeItem('auth_app_token');// Asumiendo que guardas el token aquí
    localStorage.clear();
    this.router.navigate(['login']); // Redirige al usuario a la página de inicio de sesión
  }

  private handleError(error: HttpErrorResponse) {
    console.error(`Backend returned code ${error.status}, body was:`, error.error);
    // Accediendo al mensaje de error específico
    let errorMessage = error.error;
    if (error.status === 401) {
      // Accede directamente al mensaje personalizado de error, si existe.
      errorMessage = error.error || 'Credenciales inválidas. Por favor, verifica tu correo y contraseña.';
    }
    console.log(errorMessage);
    // Otras condiciones para diferentes códigos de estado podrían ir aquí...

    return throwError(errorMessage);
  }
}

