import { DecimalPipe } from "@angular/common";
import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { Observable } from "rxjs";
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { TableService } from "src/app/shared/service/table.service";
import { UsuariosService } from "src/app/shared/service/usuarios.service";
import { PuntosCarteraService } from "src/app/shared/service/usuarios-cartera.service";
import { UserListDB, USERLISTDB } from "src/app/shared/tables/list-users";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

@Component({
  selector: "app-list-user",
  templateUrl: "./list-user.component.html",
  styleUrls: ["./list-user.component.scss"],
  providers: [TableService, DecimalPipe],
})
export class ListUserComponent implements OnInit {
  public user_list = [];
  public tableItem$: Observable<UserListDB[]>;
  public searchText = "";
  id_empresa: number;
  usuarios: any;
  filteredUsuarios: any;
  total: any;
  page = 1;
  pageSize = 10;
  paginatedUsuarios: any;

  selectedUser: any;

  constructor(
    public service: TableService,
    public usuariosService: UsuariosService,
    public puntosCarteraService: PuntosCarteraService,
    private router: Router
  ) {
    this.tableItem$ = service.tableItem$;
    this.service.setUserData(USERLISTDB);
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  ngOnInit() {
    localStorage.removeItem("selectedUserId");
    this.id_empresa = Number(localStorage.getItem("empresas"));
    this.getUsuariosEmpresa(this.id_empresa);
  }

  // Obtener los usuarios de una empresa según el id de la empresa
  getUsuariosEmpresa(id: number) {
    this.usuariosService.getAllUsuariosEmpresa(id).subscribe(
      (response) => {
        this.usuarios = response;
        this.filteredUsuarios = this.usuarios; // Inicialmente, todos los usuarios están en la lista filtrada
        this.total = this.filteredUsuarios.length;
        this.paginateEvents();
      },
      (error) => {
        console.error("Error al obtener los usuarios:", error);
      }
    );
  }

  selectUser(userId: any) {
    localStorage.setItem("selectedUserId", userId);
    this.router.navigate(["/users/user-details"]);
  }

  deleteUsuario(id: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar!'
    }).then((result) => {
      if (result.value) {
        this.usuariosService.deleteUsuario(id).subscribe(
          () => {
            console.log('Usuario eliminado correctamente');
            this.getUsuariosEmpresa(this.id_empresa);
          },
          (error) => {
            console.error('Error al eliminar el usuario:', error);
          }
        );
      }
    });
  }

  


  paginateEvents() {
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedUsuarios = this.filteredUsuarios.slice(startIndex, endIndex);
    console.log(this.paginatedUsuarios);
    console.log(this.page);
  }

  onPageChange(newPage: number) {
    this.service.page = newPage;
    this.paginateEvents();
  }

  onSearch() {
    this.filteredUsuarios = this.usuarios.filter((usuario) =>
      usuario.nombre.toLowerCase().includes(this.searchText.toLowerCase())
    );
    this.total = this.filteredUsuarios.length;
    this.paginateEvents();
  }
}
