import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListCouponComponent } from './list-coupon/list-coupon.component';
import { CreateCouponComponent } from './create-coupon/create-coupon.component';
import { EditCouponComponent } from './list-coupon/edit-coupon/edit-coupon.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list-coupon',
        component: ListCouponComponent,
        data: {
          title: "Lista de Cupones",
          breadcrumb: "Lista de Cupones"
        }
      },
      {
        path: 'create-coupon',
        component: CreateCouponComponent,
        data: {
          title: "Crear Cupones",
          breadcrumb: "Crear Cupones"
        }
      },
      {
        path: 'list-coupon/edit-coupon',
        component: EditCouponComponent,
        data: {
          title: "Editar Cupon",
          breadcrumb: "Editar Cupon"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CouponsRoutingModule { }
