<div class="page-main-header" [class.open]="open">
    <div class="main-header-right row">
        <div class="main-header-left d-lg-none col">
            <div class="logo-wrapper"><a [routerLink]="''"><img class="blur-up lazyloaded"
                        src="assets/images/dashboard/mypuntos-letras.webp" width="200px" style="margin-right: 0%;"></a></div>
        </div>
        <div class="mobile-sidebar col">
            <div class="media-body switch-sm">
                <label class="switch">
                    <a>
                        <app-feather-icons id="sidebar-toggle" [icon]="'align-left'" (click)="collapseSidebar()">
                        </app-feather-icons>
                    </a>
                </label>
            </div>
        </div>

        <div class="nav-right col">
            <ul class="nav-menus" [ngClass]="{'open' : openNav}">
                <li class="onhover-dropdown">
                    <div class="media d-flex align-items-center user"><img
                            class="align-self-center pull-right img-50 rounded-circle"
                            src="assets/images/dashboard/man.png" alt="header-user">
                        <h6 class="f-14">{{ nombre }} {{user.apellidos}} <br> {{user.email}} </h6>
                    </div>
                    <ul class="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                        <li>
                            <a [routerLink]="'/login'">
                                <app-feather-icons [icon]="'log-out'" (click)="onLogout()"></app-feather-icons>Logout
                            </a>
                        </li>
                        <li class="onhover-dropdown">
                            <a (click)="toggleNotifications()">
                                <app-feather-icons [icon]="'bell'"></app-feather-icons>Notificaciones
                            </a>
                            <ul class="notification-dropdown onhover-show-div" *ngIf="showNotifications">
                                <li>Notificaciones</li>
                                <li *ngFor="let notification of notifications">
                                    <div class="media">
                                        <div class="media-body">
                                            <h6 class="mt-0">{{ notification.title }}</h6>
                                            <p>{{ notification.message }}</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
                <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
            </div>
        </div>
        

    </div>
</div>

