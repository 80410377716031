import { DecimalPipe } from "@angular/common";
import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { Observable } from "rxjs";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/app/shared/directives/NgbdSortableHeader";
import { TableService } from "src/app/shared/service/table.service";
import { LISTCOUPLEDB, ListCouponsDB } from "src/app/shared/tables/list-coupon";
import { CuponesService } from "src/app/shared/service/cupones.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

@Component({
  selector: "app-list-coupon",
  templateUrl: "./list-coupon.component.html",
  styleUrls: ["./list-coupon.component.scss"],
  providers: [TableService, DecimalPipe],
})
export class ListCouponComponent implements OnInit {
  cupones: any;
  cupon: any;
  cuponesSeleccionados = [];

  public selected = [];

  public tableItem$: Observable<ListCouponsDB[]>;
  public searchText;
  total: any;
  id_empresa: number;
  page = 1;
  pageSize = 10;
  paginatedCupones: any;
  filteredCupones: any;

  constructor(
    public service: TableService,
    private cuponesService: CuponesService,
    private router: Router
  ) {
    this.tableItem$ = service.tableItem$;
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSelect(cupon: any) {
    console.log(cupon);
    console.log(this.cuponesSeleccionados);
    if (cupon.seleccionado) {
      this.cuponesSeleccionados.push(cupon);
    } else {
      this.cuponesSeleccionados = this.cuponesSeleccionados.filter(
        (c) => c.id !== cupon.id
      );
    }
    console.log(this.cuponesSeleccionados);
  }

  ngOnInit() {
    this.id_empresa = Number(localStorage.getItem("empresas"));
    this.getCuponesEmpresa(this.id_empresa);
  }

  // Metodo para obtener los cupones de una empresa segun el id de la empresa
  getCuponesEmpresa(id: number) {
    this.cuponesService.getCuponesEmpresa(id).subscribe(
      (res) => {
        this.cupones = res;
        this.filteredCupones = this.cupones; // Initialize filtered coupons
        this.total = this.cupones.length; // Actualiza el total después de obtener los cupones
        this.paginateEvents(); // Actualiza la paginación
      },
      (error) => {
        console.error("Error al encontrar los cupones:", error);
      }
    );
  }

  // Metodo para cambiar el estado de un cupon
  cambiarEstadoCupon(cupon: any) {
    const cuponActualizado = { activo: !cupon.activo };
    this.cuponesService.updateCuponEstado(cupon.id, cuponActualizado).subscribe(
      (response) => {
        console.log("Cupón actualizado:", response);
        // Esto depende de cómo estés manejando los datos en tu componente
        this.getCuponesEmpresa(this.id_empresa);
      },
      (error) => {
        console.error("Error al actualizar el cupón:", error);
      }
    );
  }

  deleteCupon() {
    const idsParaEliminar = this.cuponesSeleccionados.map((cupon) => cupon.id);

    if (idsParaEliminar.length > 0) {
      this.cuponesService.deleteCupones(idsParaEliminar).subscribe(
        () => {
          console.log("Cupones eliminados correctamente");
          this.getCuponesEmpresa(this.id_empresa);
          this.cuponesSeleccionados = []; // Limpiar la selección
        },
        (error) => {
          console.error("Error al eliminar cupones:", error);
        }
      );
    } else {
      Swal.fire("Por favor, selecciona al menos un cupón para eliminar");
    }
  }

  withConfirmation(id: number) {
    Swal.fire({
      title: "¿Estás seguro de que quieres eliminar este producto?",
      text: "No podrás recuperarlo",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, quiero eliminarlo",
    }).then((result) => {
      if (result.value) {
        Swal.fire("Eliminado!");
        this.deleteProduct(id);
        this.getCuponesEmpresa(this.id_empresa);
      }
    });
  }

  // Metodo para elimianar un cupon
  deleteProduct(id: number) {
    this.cuponesService.deleteCupon(id).subscribe(
      () => {
        console.log("Cupon eliminado correctamente");
        this.getCuponesEmpresa(this.id_empresa);
        // Realiza cualquier otra acción necesaria después de eliminar el producto
      },
      (error) => {
        console.error("Error al eliminar el cupon:", error);
        // Maneja el error de acuerdo a tus necesidades
      }
    );
  }

  paginateEvents() {
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedCupones = this.filteredCupones.slice(startIndex, endIndex);
    console.log(this.paginatedCupones);
    console.log(this.page);
  }

  onPageChange(newPage: number) {
    this.service.page = newPage;
    this.paginateEvents();
  }

  goToEdit(cupon: any) {
    // Almacena el cupon seleccionado en localStorage
    localStorage.setItem("cuponSeleccionado", JSON.stringify(cupon));

    // Redirige a la página de edición
    this.router.navigate(["coupons/list-coupon/edit-coupon"]);
  }

  // Method to handle the search functionality
  onSearch(searchValue: string) {
    this.filteredCupones = this.cupones.filter((cupon) =>
      cupon.detalles.toLowerCase().includes(searchValue.toLowerCase())
    );
    this.total = this.filteredCupones.length;
    this.paginateEvents();
  }
}
