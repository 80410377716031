import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { SortEvent } from "src/app/shared/directives/shorting.directive";
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { TableService } from "src/app/shared/service/table.service";
import { DIGITALLIST, DigitalListDB } from "src/app/shared/tables/digital-list";
import { ProductosService } from "src/app/shared/service/productos.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-digital-list",
  templateUrl: "./digital-list.component.html",
  styleUrls: ["./digital-list.component.scss"],
  providers: [TableService, DecimalPipe],
})
export class DigitalListComponent implements OnInit {
  tableItem$: Observable<DigitalListDB[]>;
  productos: any;
  ofertas: any;
  categoriaSeleccionada: any;
  producto: any;
  id_empresa: number;
  public digital_categories = [];
  total: any;
  page = 1;
  pageSize = 10;
  paginatedProductos: any;
  filteredProductos: any;

  constructor(
    private productosService: ProductosService,
    public service: TableService,
    private modalService: NgbModal,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {
    this.tableItem$ = service.tableItem$;
    this.service.setUserData(DIGITALLIST);
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  ngOnInit() {
    // Metodo para obtener la ID_PROFESIONAL del localStorage pero al obtenerla pasarla a tipo number
    this.id_empresa = Number(localStorage.getItem("empresas"));
    this.getProductosEmpresa(this.id_empresa);
  }

  getCategoria() {
    this.productosService
      .getProductoCategoria(this.categoriaSeleccionada)
      .subscribe(
        (res) => {
          this.categoriaSeleccionada = res[0];
          console.log(this.categoriaSeleccionada);
          this.categoriaSeleccionada = this.categoriaSeleccionada.id;
        },
        (error) => {
          console.error("Obtener categoria:", error);
        }
      );
  }

  // Metodo para obtener todos los productos de una empresa segun el id de la empresa
  getProductosEmpresa(id: number) {
    this.productosService.getAllProductosEmpresa(id).subscribe(
      (res) => {
        this.productos = res;
        this.filteredProductos = this.productos; // Initialize filtered products
        this.total = this.productos.length; // Actualiza el total después de obtener los productos
        this.paginateEvents(); // Actualiza la paginación
      },
      (error) => {
        console.error("Error al encontrar los productos:", error);
      }
    );
  }

  goToEdit(producto: any) {
    // Almacena el producto seleccionado en localStorage
    localStorage.setItem("productoSeleccionado", JSON.stringify(producto));

    // Redirige a la página de edición
    this.router.navigate(["products/digital/digital-product-list/edit"]);
  }

  deleteProduct(id: number) {
    this.productosService.deleteProducto(id).subscribe(
      () => {
        console.log("Producto eliminado correctamente");
        this.getProductosEmpresa(this.id_empresa);
      },
      (error) => {
        console.error("Error al eliminar el producto:", error);
      }
    );
  }

  withConfirmation(id: number) {
    Swal.fire({
      title: "¿Estás seguro de que quieres eliminar este producto?",
      text: "No podrás recuperarlo",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, quiero eliminarlo",
    }).then((result) => {
      if (result.value) {
        Swal.fire("Eliminado!");
        this.deleteProduct(id);
        this.getProductosEmpresa(this.id_empresa);
      }
    });
  }

  paginateEvents() {
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedProductos = this.filteredProductos.slice(
      startIndex,
      endIndex
    );
    console.log(this.paginatedProductos);
    console.log(this.page);
  }

  onPageChange(newPage: number) {
    this.service.page = newPage;
    this.paginateEvents();
  }

  // Method to handle the search functionality
  onSearch(searchValue: string) {
    this.filteredProductos = this.productos.filter((producto) =>
      producto.nombre.toLowerCase().includes(searchValue.toLowerCase())
    );
    this.total = this.filteredProductos.length;
    this.paginateEvents();
  }
}
