import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CategoriasService } from 'src/app/shared/service/categorias.service';
import { ProductosService } from 'src/app/shared/service/productos.service';
import { json } from 'stream/consumers';
import { Router } from '@angular/router';
import { BASE_URL_API } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit {
  public Editor = ClassicEditor;
  nombre: any;
  descripcion: any;
  categoriaSeleccionada: any;
  categoria:any;
  precio:any;
  imagen: any;
  oferta: any;
  precio_oferta: any;
  en_oferta:any;
  categorias:any;
  producto:any
  categoria_id: any;
  activo:any;
  
  constructor(
    private categoriasService: CategoriasService,
    private productosService: ProductosService,
    private router: Router,
    private http: HttpClient
  ) { }

  files: File[] = [];

  onSelect(event) {
    const acceptedFileTypes = ['image/jpeg', 'image/png'];
    const file = event.addedFiles[0]; // Tomar solo el primer archivo añadido
    if (file && acceptedFileTypes.includes(file.type)) {
      this.files = [file];
      this.uploadFile();
      console.log(this.files);
    } else {
      // Manejar el caso de archivo rechazado
      console.error('Tipo de archivo no permitido.');
    }
  }

  uploadFile(): void {
    if (this.files.length > 0) {
      const formData = new FormData();
      formData.append('image', this.files[0], this.files[0].name); // Usar 'image' como clave
  
      this.http.post(BASE_URL_API + '/productos/subir_archivo', formData).subscribe({
        next: (response: any) => {
          console.log('Archivo subido con éxito', response);
  
          // Asumiendo que la respuesta del servidor incluye un campo 'filename' o 'filePath'
          this.imagen = 'http://localhost:3000/dist/upload/'+response.filename; // o response.filePath, dependiendo de la respuesta del servidor
          console.log(this.imagen)
  
          // Si necesitas la URL completa del archivo, puedes construirla aquí
          // Por ejemplo: this.imagenUrl = ${BASE_URL_API}/path/to/images/${response.filename};
        },
        error: (error) => console.error('Error al subir el archivo', error)
      });
    }
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  ngOnInit() {
    this.getAllCategorias()
    this.producto =JSON.parse(localStorage.getItem('productoSeleccionado'));
    console.log(this.producto)
    this.nombre= this.producto.nombre;
    this.descripcion= this.producto.descripcion;
    this.precio= this.producto.precio;
    this.imagen = this.producto.imagen;
    this.precio_oferta = this.producto.precio_oferta;
    this.oferta= this.producto.en_oferta;
    this.categoria= this.categoriaSeleccionada;
    this.getCategoria();
  }
  
  onChangeCategoria(newValue: string) {
    this.categoriaSeleccionada = Number(newValue);
    console.log(this.categoriaSeleccionada);
}
  getCategoria() {
    if (this.producto && this.producto.id) {
      this.productosService.getCategoriaProducto(this.producto.id).subscribe({
        next: (categorias) => {
          if (categorias && categorias.length > 0) {
            // Asume que la primera categoría es la relevante
            this.categoriaSeleccionada = categorias[0].id;
            // Para mostrar el nombre, guarda el objeto de la categoría
            this.categoria = categorias[0];
          }
        },
        error: (error) => {
          console.error('Error al cargar la categoría:', error);
        }
      });
    }
  }

  


  updateProducto(){
   let productoData = {
    id_empresa: 1,
    imagen: this.imagen,
    codigo: 4214,
    nombre : this.nombre,
    categoria_id: this.categoriaSeleccionada,
    precio:this.precio,
    precio_oferta:this.precio_oferta,
    descripcion: this.descripcion,
    en_oferta: this.oferta
    }
    console.log(productoData);
    this.productosService.updateProducto(this.producto.id, productoData ).subscribe(
      response => {
        console.log('producto actualizado correctamente')
        this.goToList();
      }, 
      error=> {
        console.log('error')
      }
    )
  }

  // toggleEstadoProducto(producto: any) {
  //   const productoActualizado = { ...producto, activo: !producto.activo };
  //   this.productosService.updateProducto(producto.id, productoActualizado).subscribe(
  //     response => {
  //       console.log('Cupón actualizado:', response);
  //     },
  //     error => {
  //       console.error('Error al actualizar el cupón:', error);
  //     }
  //   );
  // }


  getAllCategorias(){
    this.categoriasService.getAllCategorias(). subscribe(
      res => {
        this.categorias = res;
        //console.log(this.categorias);
      }, 
      error => {
        console.error('Error al añadir worker:', error);
      }
    )
  }

  goToList(){
    this.router.navigate(['products/digital/digital-product-list']);
  }

  onUrlChange(event: any) {
    this.imagen = event.target.value;
    console.log(this.imagen);
  }

}
