
  <div class="row">
      <div class="col-sm-12">
          <div class="card">
              <div class="card-header">
                  <h5>Usuarios</h5>
              </div>
              <div class="card-body">
                  <div id="batchDelete" class="custom-datatable">
                      <div class="table-responsive">
                          <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col" sortable="order_id" (sort)="onSort($event)">ID</th>
                                  <th scope="col" sortable="product" (sort)="onSort($event)">Usuario</th>
                                  <th scope="col" sortable="payment_status" (sort)="onSort($event)">Producto</th>
                                  <th scope="col" sortable="paymeny_method" (sort)="onSort($event)">Codigo</th>
                                  <th scope="col" sortable="order_status" (sort)="onSort($event)">Cupon</th>
                                  <th scope="col" sortable="date" (sort)="onSort($event)">Puntos canjeados</th>
                                  <th scope="col" sortable="status" (sort)="onSort($event)">Puntos anteriores</th>
                                  <th scope="col" sortable="status" (sort)="onSort($event)">Puntos posteriores</th>
                                  <th scope="col" sortable="total" (sort)="onSort($event)">Fecha</th>
                                </tr>
                              </thead>
                              <tbody >
                              <tr *ngFor="let user of usuarios">
                                <th scope="row">{{ user.id}}</th>
                                <td>{{ user.id_usuario }}</td>
                                <td>{{ user.nombre_producto }}</td>
                                <td>{{ user.codigo }}</td>
                                <td>{{ user.cupon }}</td>
                                <td>{{ user.puntos_canjeados }}</td>
                                <td>{{ user.puntos_anteriores }}</td>
                                <td>{{ user.puntos_posteriores }}</td>
                                <td>{{user.fecha |date:'dd/MM/yyyy HH:mm:ss'}}</td>

                                  <!-- <td>
                                      <a href="javascript:void(0)" (click)="verPedido(user)" ><i class='fa fa-eye'></i></a>&nbsp;
      
                                  </td> -->
                                  
                              </tr>                              
                              </tbody>
                
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

<!-- Modal -->
<ng-template #confirmModalContent let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="confirmModalLabel">Confirmación</h5>
    <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <p>¿Estás seguro de que quieres guardar el curso?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('cancel')">Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('confirm')">Confirmar</button>
  </div>
</ng-template>
