import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { CuponesService } from 'src/app/shared/service/cupones.service';
import { CategoriasService } from 'src/app/shared/service/categorias.service';
import { ProductosService } from 'src/app/shared/service/productos.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-create-coupon',
  templateUrl: './create-coupon.component.html',
  styleUrls: ['./create-coupon.component.scss']
})
export class CreateCouponComponent implements OnInit {
  public model: NgbDateStruct;
  public date: { year: number, month: number };
  public modelFooter: NgbDateStruct;
  public active = 1;
  categoriaSeleccionada: any;
  categorias: any;
  productos: any;
  productoSeleccionado:any;
  detalles: any;
  codigo: any;
  puntos_necesarios:any;
  activo: any;
  img: any;
  fecha_inicio: any;
  valorConvertido: any;
  id: number;

  constructor(
     private calendar: NgbCalendar,
     private cuponesService: CuponesService,
     private router: Router,
     private categoriasService: CategoriasService,
     private productosService: ProductosService,
     
     ) {
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  ngOnInit() {
    this.id = Number(localStorage.getItem('empresas'));
    this.getAllCategorias();
  }


  onChangeCategoria(newValue: string) {
    this.categoriaSeleccionada = Number(newValue);
    this.loadProductos(this.categoriaSeleccionada);
    console.log(this.categoriaSeleccionada);
}


  loadProductos(categoriaId: number) {
    this.productosService.getProductoCategoria(categoriaId).subscribe(
        data => {
            console.log(data); // Imprimir para depuración
            this.productos = data;
        },
        error => {
            console.error('Error al cargar productos:', error);
        }
    );
    console.log(this.productoSeleccionado)
  }


onChangeProductoSeleccionado(newValue: string) {
  this.productoSeleccionado = Number(newValue)
  console.log('Producto seleccionado:', this.productoSeleccionado);
}


formatFechaInicio(fecha: NgbDateStruct): string {
  if (fecha) {
    const paddedMonth = fecha.month.toString().padStart(2, '0');
    const paddedDay = fecha.day.toString().padStart(2, '0');
    return `${fecha.year}-${paddedMonth}-${paddedDay} 00:00:00`;
  }
  return '';
}

  addCupon(){
    // Metodo el cual mira si acctivo es true o false, si es false lo convierte a 0 y si es true lo convierte a 1
    if(this.activo == true){
      this.activo = 1;
    }
    else{
      this.activo = 0;
    }
    const fechaFormateada = this.formatFechaInicio(this.fecha_inicio);

     let producto = {
      id_producto:this.productoSeleccionado,
      detalles : this.detalles,
      puntos_necesarios: this.puntos_necesarios,
      puntos_requeridos:this.puntos_necesarios,
      fecha_inicio:fechaFormateada,
      activo: this.activo,
      img: this.img,
      id_empresa: this.id
      }
      this.cuponesService.addCupon(producto).subscribe(
        response => {
          console.log('producto añadido correctamente');
          this.router.navigate(['coupons/list-coupon']);
        }, 
        error=> {
          console.log('error')
        }
      )
      console.log(producto)
    }



    getAllCategorias(){
      this.categoriasService.getAllCategorias(). subscribe(
        res => {
          this.categorias = res;
          console.log(this.categorias);
        }, 
        error => {
          console.error('Error al añadir worker:', error);
        }
      )
    }

    onUrlChange(event: any) {
      this.img = event.target.value;
      console.log(this.img);
    }

}
