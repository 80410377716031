import { Injectable } from '@angular/core';
import { BASE_URL_API } from './../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Cupon } from '../model/cupon.model';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CuponesService {

constructor(private http: HttpClient) { }

private getHeaders(): HttpHeaders {
  let headers = new HttpHeaders().set('Content-Type', 'application/json');
  // Si auth_user no existe, entonces se obtiene el token de auth_app_token.
  let authUserItem;
  if(localStorage.getItem('auth_user') == null){
    authUserItem = localStorage.getItem('auth_app_token');
  } else {
    // Si auth_user existe, entonces se obtiene el token de auth_user.
    authUserItem = localStorage.getItem('auth_user');
  }

  // Si existe el token, se agrega al header.
  if (authUserItem) {
    const token = JSON.parse(authUserItem).token;
    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }
  }
   
  return headers;
}

getAllCupones(): Observable<Cupon[]> {
  return this.http.get<Cupon[]>(`${BASE_URL_API}/cupones/`, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

//Metodo para obtener los cupones de una empresa
getCuponesEmpresa(id: number): Observable<Cupon[]> {
  return this.http.get<Cupon[]>(`${BASE_URL_API}/cupones/empresa/${id}`, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

getCuponById(id: number): Observable<Cupon> {
  return this.http.get<Cupon>(`${BASE_URL_API}/cupones/${id}`, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

getCuponCategoria(id: number): Observable<any> {
  return this.http.get<any>(`${BASE_URL_API}/categorias/categoria/${id}`, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

addCupon(productoData: any): Observable<any> {
  return this.http.post<any>(`${BASE_URL_API}/cupones/`, productoData, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}
 
updateCupon(id: number, productoData: any): Observable<Cupon> {
  return this.http.put<Cupon>(`${BASE_URL_API}/cupones/${id}`, productoData, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

updateCuponEstado(id: number, productoData: any): Observable<Cupon> {
  return this.http.put<Cupon>(`${BASE_URL_API}/cupones/estado/${id}`, productoData, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

deleteCupon(id: number): Observable<any> {
  return this.http.delete<any>(`${BASE_URL_API}/cupones/${id}`, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

deleteCupones(ids: number[]): Observable<any> {
  return this.http.post<any>(`${BASE_URL_API}/cupones/deleteBatch`, { ids }, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

private handleError(error: HttpErrorResponse) {
  console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
  return throwError('Algo malo ocurrió; por favor, inténtalo de nuevo más tarde.');
}

}
