<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-5 p-0 card-left">
                    <div class="card bg-secundary mi-bg-personalizado">
                        <div class="logo" >
                            <img src="../../../../assets/images/mypuntos-logo.svg" alt="logo" class="img-fluid">
                        </div>
                        <div class="single-item">
                            <owl-carousel-o [options]="owlcarouselOptions">
                                <ng-container *ngFor="let data of owlcarousel">
                                    <ng-template carouselSlide class="item">
                                        <div class="info-logo">
                                            <h3>{{data.title}}</h3>
                                            <p>{{data.desc}}</p>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
                <div class="col-md-7 p-0 card-right">
                    <div class="card tab2-card">
                        <div class="card-body login-card">
                                    <div class="tab-pane fade active show" id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                              
                                        <div class="form-group">
                                            <input required="" name="email" [(ngModel)]="email" type="email"
                                                class="form-control" placeholder="Email" maxlength="100"
                                                id="exampleInputEmail1">
                                        </div>
                                        <div class="form-group">
                                            <input required="" name="pass" [(ngModel)]="pass" type="password"
                                                class="form-control" placeholder="Contraseña" maxlength="199">
                                        </div>
                                        <div class="form-button">
                                             <button class="btn mi-bg-personalizado" (click)="onSubmit()">Login</button>
                                        </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-6 footer-copyright">
            <p class="mb-0">Copyright 2024 © Acciogest All rights reserved.</p>
        </div>       
    </div>
</div>
