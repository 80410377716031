import { AuthService } from './../../service/auth.service';
import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NavService } from '../../service/nav.service';
import { UsuariosService } from '../../service/usuarios.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import jsPDF from 'jspdf';
import { UsuariosPanelService } from '../../service/usuarios_panel.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile : boolean;
  

  @Output() rightSidebarEvent = new EventEmitter<boolean>();
  showNotifications: boolean = false;
  notifications: { title: string; message: string; }[];
  isSidebarOpen: boolean;
  fechasAleatorias: any;
  email: any;
  nombre: any;
  user_id:any
  user:any;

  constructor(
    public navServices: NavService,
    public authService: AuthService,
    public UsuariosService: UsuariosService,
    private modalService: NgbModal,
    private usuariosPanelService: UsuariosPanelService
    ) { }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }


  ngOnInit() { 
    this.nombre = JSON.parse(localStorage.getItem('auth_app_token')).nombre;
    this.user_id = JSON.parse(localStorage.getItem('auth_app_token')).id;
    console.log(this.user_id)
    this.loadRandomNotifications();
    this.getUser(this.user_id)

  }

  // Metodo para obtener todos los usuarios
  getAllUsuarios() {
    this.UsuariosService.getAllUsuarios().subscribe(usuarios => {
      console.log(usuarios);
    });
  }

  getUser(id:any){
    this.usuariosPanelService.getUsuario(this.user_id).subscribe(usuario =>{
      this.user = usuario;
      console.log(this.user)
    })
  }

  onLogout() {
    this.authService.logout();
  }

  toggleNotifications() {
    this.showNotifications = !this.showNotifications;
  }

  loadRandomNotifications() {
    this.notifications = [
      { title: 'Poco stock', message: 'Recuerda que faltan reservas de desyunos.' },
      { title: 'Nuevo cliente', message: 'Contactar con el .' }
      // Agrega más notificaciones según necesites
    ];
  }

}
