import { UsuarioHistorial } from "./../model/usuario_historial.model";
import { Injectable } from "@angular/core";
import { BASE_URL_API } from "../../../environments/environment";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class Usuarios_historialService {
  constructor(private http: HttpClient) {}

  private getHeaders(): HttpHeaders {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    // Si auth_user no existe, entonces se obtiene el token de auth_app_token.
    let authUserItem;
    if (localStorage.getItem("auth_user") == null) {
      authUserItem = localStorage.getItem("auth_app_token");
    } else {
      // Si auth_user existe, entonces se obtiene el token de auth_user.
      authUserItem = localStorage.getItem("auth_user");
    }

    // Si existe el token, se agrega al header.
    if (authUserItem) {
      const token = JSON.parse(authUserItem).token;
      if (token) {
        headers = headers.set("Authorization", `Bearer ${token}`);
      }
    }

    return headers;
  }

  private handleError(error: any) {
    // Manejo de errores
    console.error("An error occurred", error);
    return throwError(error);
  }

  getAllHistoriales(): Observable<UsuarioHistorial[]> {
    return this.http
      .get<UsuarioHistorial[]>(`${BASE_URL_API}/usuarios_historial`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  getHistorialByIdEmpresa(id_empresa: string): Observable<any> {
    return this.http
      .get<UsuarioHistorial[]>(
        `${BASE_URL_API}/usuarios_historial/empresa/${id_empresa}`,
        { headers: this.getHeaders() }
      )
      .pipe(catchError(this.handleError));
  }

  getHistorialLogsByIdEmpresa(id_empresa: string): Observable<any> {
    return this.http
      .get(`${BASE_URL_API}/usuarios_logs/empresa/${id_empresa}`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  // Add this method to UsuariosService
  getUserHistoryById(userId: string): Observable<any> {
    return this.http
      .get(`${BASE_URL_API}/usuarios_historial/usuario/${userId}`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  createHistorial(historial: UsuarioHistorial): Observable<UsuarioHistorial> {
    return this.http
      .post<UsuarioHistorial>(`${BASE_URL_API}/usuarios_historial`, historial, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  updateHistorial(
    id: number,
    historial: UsuarioHistorial
  ): Observable<UsuarioHistorial> {
    return this.http
      .put<UsuarioHistorial>(
        `${BASE_URL_API}/usuarios_historial/${id}`,
        historial,
        { headers: this.getHeaders() }
      )
      .pipe(catchError(this.handleError));
  }

  deleteHistorial(id: number): Observable<{}> {
    return this.http
      .delete<{}>(`${BASE_URL_API}/usuarios_historial/${id}`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  // Add this method to Usuarios_historialService
  getLogsByUserId(userId: string): Observable<any> {
    return this.http
      .get(`${BASE_URL_API}/usuarios_logs/usuario/${userId}`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }
}
