import { UsuariosService } from './../../../shared/service/usuarios.service';
import { DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { SortEvent } from 'src/app/shared/directives/shorting.directive';
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { TableService } from 'src/app/shared/service/table.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  providers: [TableService, DecimalPipe],
})

export class OrdersComponent implements OnInit {

  usuarios:any;

  constructor(
    public service: TableService,
    private modalService: NgbModal,
    private https: HttpClient,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private usuariosService: UsuariosService
    ) {


  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    console.log("ddsds");

    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  ngOnInit() { 
    var t = localStorage.getItem('auth_app_token');
    this.getUsuariosHistory();
  }

  getUsuariosHistory(){
    this.usuariosService.getAllUsuariosHistory(). subscribe(
      res => {
        this.usuarios = res;
        console.log(this.usuarios);
      }, 
      error => {
        console.error('Error al añadir worker:', error);
      }
    )
  }


  // aplicarFiltro() {
  //     // Filtrar los datos según el estado seleccionado
  //   if (this.estadoFiltro === '') {
  //     // No hay filtro, muestra todos los pedidos
  //     this.datosFiltrados = this.datos;
  //   } else {
  //     // Aplica el filtro según el estado
  //     this.datosFiltrados = this.datos.filter(item => item.estado === this.estadoFiltro);
  //   }
  // }

}
