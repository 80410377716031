import { DecimalPipe, DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Chart } from "chart.js";
import { TableService } from "src/app/shared/service/table.service";
import { Usuarios_historialService } from "src/app/shared/service/usuarios_historial.service";
import { UsuariosService } from "src/app/shared/service/usuarios.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  providers: [TableService, DecimalPipe, DatePipe],
})
export class DashboardComponent implements OnInit {
  id_empresa = localStorage.getItem("empresas");
  historialProductos: any = [];
  historialLogs: any = [];
  membresias: any = [];

  historialProductosChart: any;
  historialLogsChart: any;
  membresiasChart: any;

  currentMonth: number = new Date().getMonth(); // Month starts from 0
  currentYear: number = new Date().getFullYear();
  selectedDate: Date = new Date();

  monthNames: string[] = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  constructor(
    private usuariosHistorialService: Usuarios_historialService,
    private usuariosService: UsuariosService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.loadHistorialProductos();
    this.loadHistorialLogs();
    this.loadMembresias();
  }

  loadHistorialProductos() {
    this.usuariosHistorialService
      .getHistorialByIdEmpresa(this.id_empresa)
      .subscribe(
        (data) => {
          this.historialProductos = data;
          this.updateHistorialProductosChart();
          console.log(data);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  loadHistorialLogs() {
    this.usuariosHistorialService
      .getHistorialLogsByIdEmpresa(this.id_empresa)
      .subscribe(
        (data) => {
          this.historialLogs = data;
          this.updateHistorialLogsChart();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  loadMembresias() {
    this.usuariosService.getNumUsuariosMembresia(this.id_empresa).subscribe(
      (data) => {
        this.membresias = data;
        this.updateMembresiasChart();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  formatFecha(fecha: string): string {
    return this.datePipe.transform(fecha, "dd/MM") || "";
  }

  filterDataByMonth(data: any[], dateField: string) {
    return data.filter((item) => {
      const date = new Date(item[dateField]);
      return (
        date.getMonth() === this.currentMonth &&
        date.getFullYear() === this.currentYear
      );
    });
  }

  changeMonth(offset: number) {
    this.currentMonth += offset;
    if (this.currentMonth < 0) {
      this.currentMonth = 11;
      this.currentYear--;
    } else if (this.currentMonth > 11) {
      this.currentMonth = 0;
      this.currentYear++;
    }
    this.updateHistorialProductosChart();
    this.updateHistorialLogsChart();
  }

  updateHistorialProductosChart() {
    // Agrupar datos por nombre de producto
    const filteredData = this.filterDataByMonth(
      this.historialProductos,
      "fecha"
    );
    const groupedData: { [key: string]: number } = {};

    filteredData.forEach((item) => {
      if (!groupedData[item.nombre_producto]) {
        groupedData[item.nombre_producto] = 0;
      }
      groupedData[item.nombre_producto]++;
    });

    const labels = Object.keys(groupedData);
    const data = Object.values(groupedData);

    const ctx = document.getElementById(
      "historialProductosChart"
    ) as HTMLCanvasElement;
    if (this.historialProductosChart) {
      this.historialProductosChart.destroy();
    }
    this.historialProductosChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Cantidad de Productos",
            data: data,
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: "Producto",
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: "Cantidad",
            },
          },
        },
      },
    });
  }

  updateHistorialLogsChart() {
    const filteredData = this.filterDataByMonth(this.historialLogs, "fecha");
    const ctx = document.getElementById(
      "historialLogsChart"
    ) as HTMLCanvasElement;
    if (this.historialLogsChart) {
      this.historialLogsChart.destroy();
    }
    this.historialLogsChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: filteredData.map((item: any) => this.formatFecha(item.fecha)),
        datasets: [
          {
            label: "Historial Logs",
            data: filteredData.map((item: any) => item.cantidad_logs || 0),
            backgroundColor: "rgba(153, 102, 255, 0.2)",
            borderColor: "rgba(153, 102, 255, 1)",
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: "Fecha",
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: "Cantidad",
            },
          },
        },
      },
    });
  }

  updateMembresiasChart() {
    const ctx = document.getElementById("membresiasChart") as HTMLCanvasElement;
    if (this.membresiasChart) {
      this.membresiasChart.destroy();
    }
    this.membresiasChart = new Chart(ctx, {
      type: "pie",
      data: {
        labels: this.membresias.map(
          (item: any) => item.nombre_membresia || "Sin Membresía"
        ),
        datasets: [
          {
            label: "Membresías",
            data: this.membresias.map((item: any) => item.cantidad || 0),
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
      },
    });
  }
}
