import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListUserComponent } from './list-user/list-user.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { CreateClientComponent } from './create-client/create-client.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list-user',
        component: ListUserComponent,
        data: {
          title: "Lista de Clientes",
          breadcrumb: "Lista de clientes"
        }
      },
      {
        path: 'user-details',
        component: UserDetailComponent,
        data: {
          title: "Detalles de cliente",
          breadcrumb: "Detalles de cliente"
        }
      },
      {
        path: 'create-client',
        component: CreateClientComponent,
        data: {
          title: "Crear Cliente",
          breadcrumb: "Crear Cliente"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
