import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DigitalListComponent } from './digital/digital-list/digital-list.component';
import { DigitalAddComponent } from './digital/digital-add/digital-add.component';
import { EditProductComponent } from './digital/digital-list/edit-product/edit-product.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'digital/digital-product-list',
        component: DigitalListComponent,
        data: {
          title: "Lista de Productos",
          breadcrumb: "Lista de Productos"
        }
      },
      {
        path: 'digital/digital-product-list/edit',
        component: EditProductComponent,
        data: {
          title: 'Editar Producto',
          breadcrumb: 'Editar Producto',
        },
      },
      {
        path: 'digital/digital-add-product',
        component: DigitalAddComponent,
        data: {
          title: "Agregar Producto",
          breadcrumb: "Agregar Producto"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
