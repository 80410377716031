<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <!-- Search Bar -->
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Buscar por nombre"
              (input)="onSearch($event.target.value)"
            />
          </div>
          <div id="batchDelete" class="custom-datatable">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Codigo</th>
                    <th scope="col">Imagen</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Descripcion</th>
                    <th scope="col">Precio</th>
                    <th scope="col">En oferta</th>
                    <th scope="col">Precio oferta</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let producto of paginatedProductos">
                    <th scope="row">{{ producto.codigo }}</th>
                    <td>
                      <img
                        [src]="producto.imagen"
                        class="imgTable"
                        style="width: 20px"
                      />
                    </td>
                    <td>{{ producto.nombre }}</td>
                    <td>{{ producto.descripcion }}</td>
                    <td>{{ producto.precio }}</td>
                    <td>
                      <i
                        class="fa fa-circle mx-4"
                        [ngClass]="{
                          'estado-en-oferta': producto.en_oferta,
                          'estado-no-en-oferta': !producto.en_oferta
                        }"
                        f-12
                      ></i>
                    </td>
                    <td>{{ producto.precio_oferta }}</td>
                    <td>
                      <a href="javascript:void(0)" (click)="goToEdit(producto)"
                        ><i class="fa fa-edit f-12"></i
                      ></a>
                      &nbsp;
                      <a
                        href="javascript:void(0)"
                        (click)="withConfirmation(producto.id)"
                        ><i class="fa fa-trash-o"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-center p-2">
                <ngb-pagination
                  [collectionSize]="total"
                  [(page)]="page"
                  [pageSize]="pageSize"
                  (pageChange)="onPageChange($event)"
                >
                  <ng-template ngbPaginationNumber let-page>
                    <span
                      [ngClass]="{
                        'page-item-selected': page === service.page,
                        'page-item-unselected': page !== service.page
                      }"
                      >{{ page }}</span
                    >
                  </ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
