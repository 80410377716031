import { Component } from '@angular/core';
// import { ID_PROFESIONAL } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  title = 'multikart-backend';

  
  ngOnInit() {
    this.guardarIdProfesional();
  }

  guardarIdProfesional() {
    // localStorage.setItem('empresas', ID_PROFESIONAL);
  }

}



