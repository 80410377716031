import { Component, OnInit } from '@angular/core';
import { CategoriasService } from 'src/app/shared/service/categorias.service';
import { ProductosService } from 'src/app/shared/service/productos.service';
import { ActivatedRoute ,Router} from '@angular/router';
import { BASE_URL_API } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-digital-add',
  templateUrl: './digital-add.component.html',
  styleUrls: ['./digital-add.component.scss']
})

export class DigitalAddComponent implements OnInit {
  nombre: any;
  descripcion: any;
  categoriaSeleccionada: any;
  precio: any;
  oferta: any;
  precio_oferta: any;
  categorias: any;
  imagen: any;
  id: number;

  constructor(
    private categoriasService: CategoriasService,
    private productosService: ProductosService,
    private router: Router,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.id = Number(localStorage.getItem('empresas'));
    this.getAllCategorias();
  }

  onChangeCategoria(newValue: string) {
    this.categoriaSeleccionada = Number(newValue);
    console.log(this.categoriaSeleccionada);
  }

  addProducto() {
    let producto = {
      id_empresa: this.id,
      imagen: this.imagen,
      codigo: '',
      nombre: this.nombre,
      categoria_id: this.categoriaSeleccionada,
      precio: this.precio,
      en_oferta: this.oferta,
      precio_oferta: this.precio_oferta,
      descripcion: this.descripcion
    };
    
    this.productosService.addProducto(producto).subscribe(
      response => {
        console.log('Producto añadido correctamente');
        this.router.navigate(['products/digital/digital-product-list']);
      }, 
      error => {
        console.log('Error al añadir producto:', error);
      }
    );
  }

  getAllCategorias() {
    this.categoriasService.getAllCategorias().subscribe(
      res => {
        this.categorias = res;
        console.log(this.categorias);
      }, 
      error => {
        console.error('Error al obtener categorías:', error);
      }
    );
  }

  onUrlChange(event: any) {
    this.imagen = event.target.value;
    console.log(this.imagen);
  }
}
