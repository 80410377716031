<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <!-- Search Bar -->
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Buscar por detalles"
              (input)="onSearch($event.target.value)"
            />
          </div>
          <div class="custom-datatable">
            <div class="clearfix"></div>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Codigo</th>
                  <th scope="col">Imagen</th>
                  <th scope="col">Detalles</th>
                  <th scope="col">Puntos</th>
                  <th scope="col">Estado</th>
                  <th scope="col">Activar Cupon</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let cupon of paginatedCupones">
                  <td>
                    {{ cupon.codigo_cupon }}
                  </td>
                  <td>
                    <img
                      [src]="cupon.img"
                      class="imgTable"
                      style="width: 20px"
                    />
                  </td>
                  <td scope="row">
                    {{ cupon.detalles }}
                  </td>
                  <td>{{ cupon.puntos_necesarios }}</td>
                  <td>
                    <i
                      class="fa fa-circle mx-3"
                      [ngClass]="{
                        'estado-activo': cupon.activo,
                        'estado-inactivo': !cupon.activo
                      }"
                      f-12
                    ></i>
                  </td>
                  <td>
                    <input
                      class="mx-5"
                      type="checkbox"
                      [checked]="cupon.activo"
                      (change)="cambiarEstadoCupon(cupon)"
                    />
                  </td>
                  <td>
                    <a href="javascript:void(0)" (click)="goToEdit(cupon)"
                      ><i class="fa fa-edit f-12"></i
                    ></a>
                    &nbsp;
                    <a
                      href="javascript:void(0)"
                      (click)="withConfirmation(cupon.id)"
                      ><i class="fa fa-trash-o"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-center p-2">
              <ngb-pagination
                [collectionSize]="total"
                [(page)]="page"
                [pageSize]="pageSize"
                (pageChange)="onPageChange($event)"
              >
                <ng-template ngbPaginationNumber let-page>
                  <span
                    [ngClass]="{
                      'page-item-selected': page === service.page,
                      'page-item-unselected': page !== service.page
                    }"
                    >{{ page }}</span
                  >
                </ng-template>
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
