import { Component, OnInit } from "@angular/core";
import { CuponesService } from "src/app/shared/service/cupones.service";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-edit-coupon",
  templateUrl: "./edit-coupon.component.html",
  styleUrls: ["./edit-coupon.component.scss"],
})
export class EditCouponComponent implements OnInit {
  nombre: any;
  descripcion: any;
  categoriaSeleccionada: any;
  precio: any;
  imagen: any;
  activo: any;
  cupon: any;
  categorias: any;
  detalles: any;
  puntos_necesarios: any;
  fecha_inicio: NgbDateStruct;
  codigo_cupon: any;

  constructor(
    private cuponesService: CuponesService,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.cupon = JSON.parse(localStorage.getItem("cuponSeleccionado"));
    console.log(this.cupon);
    // Inicializar campos con los datos del cupón seleccionado
    this.codigo_cupon = this.cupon.codigo_cupon;
    this.detalles = this.cupon.detalles;
    this.puntos_necesarios = this.cupon.puntos_necesarios;
    this.fecha_inicio = this.parseDate(this.cupon.fecha_inicio); // Convertir fecha
    this.imagen = this.cupon.img;
    this.activo = this.cupon.activo;
    this.categoriaSeleccionada = this.cupon.categoria_id;
  }

  parseDate(dateString: string): NgbDateStruct {
    const date = new Date(dateString);
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  updateCupon() {
    // Método para actualizar un cupón
    const fechaFormateada = this.formatFechaInicio(this.fecha_inicio);
    let cuponData = {
      id_empresa: this.cupon.id_empresa,
      codigo_cupon: this.cupon.codigo_cupon,
      img: this.imagen,
      detalles: this.detalles,
      puntos_necesarios: this.puntos_necesarios,
      fecha_inicio: fechaFormateada,
      activo: this.activo,
    };
    console.log(cuponData);
    this.cuponesService.updateCupon(this.cupon.id, cuponData).subscribe(
      (response) => {
        console.log("Cupón actualizado correctamente");
        this.goToList();
      },
      (error) => {
        console.log("error");
      }
    );
  }

  formatFechaInicio(fecha: NgbDateStruct): string {
    if (fecha) {
      const paddedMonth = fecha.month.toString().padStart(2, "0");
      const paddedDay = fecha.day.toString().padStart(2, "0");
      return `${fecha.year}-${paddedMonth}-${paddedDay} 00:00:00`;
    }
    return "";
  }

  goToList() {
    this.router.navigate(["coupons/list-coupon"]);
  }

  onUrlChange(event: any) {
    this.imagen = event.target.value;
    console.log(this.imagen);
  }
}
