import { AuthService } from "./../../../shared/service/auth.service";
import { UsuariosPanelService } from "src/app/shared/service/usuarios_panel.service";
import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { LoginRequest } from "src/app/shared/model/auth.model";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
// import { ID_PROFESIONAL } from "src/environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  myGroup: FormGroup;
  public loginForm: FormGroup;

  public active = 1;
  public email: any = "";
  public pass: any = "";
  terminos: boolean = false;
  msgError = "";
  mostrarAnimacionLogo: boolean = false;

  constructor(
    private authService: AuthService
  ) {}

  owlcarousel = [
    {
      title: "¡Bienvenido a MyPuntos!",
      desc: "En MyPuntos, te recompensamos como te mereces. Trabajamos con las mejores marcas y productos para ofrecerte beneficios exclusivos.",
    },
  ];

  owlcarouselOptions = {
    loop: false,
    items: 1,
  };

  ngOnInit() {
    //clear localstorage
    localStorage.clear();
  }

  onSubmit() {
    const loginData: LoginRequest = {
      email: this.email,
      pass: this.pass,
    };
    this.authService.login(loginData).subscribe(
      (response) => {
        console.log("Token generado y almacenado: ", response);
        localStorage.setItem('empresas', response.userDetails.id_empresa);
        console.log(response.userDetails.id_empresa)

      },
      (error) => {
        Swal.fire({
          icon: "error",
          title: "Error al iniciar sesión",
          text: error.error,
        });
      }
    );
  }
}
