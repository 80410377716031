import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    var t = localStorage.getItem('auth_app_token');
    const isAuthenticated = t;

    if (isAuthenticated) {
      console.log('Authenticated')
      return true; // El usuario está autenticado y puede acceder a la ruta
    } else {
      console.log('Error')
      return false; // Bloquear el acceso a la ruta
    }
  }
}
