import { Routes, Router } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'products',
    loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: "Productos"
    }
  },
  {
    path: 'ventas',
    loadChildren: () => import('../../components/ventas/ventas.module').then(m => m.VentasModule),
    data: {
      breadcrumb: "Ventas"
    }
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: "Clientes"
    }
  },
  {
    path: 'administration',
    loadChildren: () => import('../../components/administration/administration.module').then(m => m.AdministrationModule),
    data: {
      breadcrumb: "Administración"
    }
  },
  {
    path: 'integrations',
    loadChildren: () => import('../../components/integrations/integrations.module').then(m => m.IntegrationsModule),
    data: {
      breadcrumb: "Integraciones"
    }
  },
  {
    path: 'documents',
    loadChildren: () => import('../../components/documents/documents.module').then(m => m.DocumentsModule),
    data: {
      breadcrumb: "Documentos"
    }
  },
  {
    path: 'verpedido',
    loadChildren: () => import('../../components/verpedido/verpedido.module').then(m => m.PedidosModule),
    data: {
      breadcrumb: "Ver Pedido"
    }
  },
  {
    path: 'verproducto',
    loadChildren: () => import('../../components/verproducto/verproducto.module').then(m => m.VerProductoModule),
    data: {
      breadcrumb: "Ver Producto"
    }
  },
  {
    path: 'coupons',
    loadChildren: () => import('../../components/coupons/coupons.module').then(m => m.CouponsModule),
    data: {
      breadcrumb: "Cupones"
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),
    data: {
      breadcrumb: "reports"
    }
  },
  {
    path: 'pages',
    loadChildren: () => import('../../components/pages/pages.module').then(m => m.PagesModule),
    data: {
      breadcrumb: "Pages"
    }
  },
  {
    path: 'workers',
    loadChildren: () => import('../../components/workers/workers.module').then(m => m.WorkersModule),
    data: {
      breadcrumb: "Trabajadores"
    }
  },
  {
    path: 'budget',
    loadChildren: () => import('../../components/budget/budget.module').then(m => m.BudgetModule),
    data: {
      breadcrumb: "Presupuestos"
    }
  },


];