<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="card">
    <div class="card-body pt-3">
      <div class="row mb-3">
        <div class="col-md-6">
          <input
            type="text"
            class="form-control"
            placeholder="Buscar por nombre"
            [(ngModel)]="searchText"
            (input)="onSearch()"
          />
        </div>
      </div>
      <div id="batchDelete" class="custom-datatable">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Apellidos</th>
                <th scope="col">Email</th>
                <th scope="col">Telefono</th>
                <th scope="col">Leads</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let usuario of paginatedUsuarios">
                <td>{{ usuario.nombre }}</td>
                <td>{{ usuario.apellidos }}</td>
                <td>{{ usuario.email }}</td>
                <td>{{ usuario.telefono }}</td>
                <td>{{ usuario.tags }}</td>
                <td>
                  <a class="edit-icon" (click)="selectUser(usuario.id)" ><i class="fa fa-edit f-12 mx-4"></i></a>&nbsp;
                  <a href="javascript:void(0)" (click)="deleteUsuario(usuario.id)"><i class="fa fa-trash-o"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-center p-2">
            <ngb-pagination
              [collectionSize]="total"
              [(page)]="page"
              [pageSize]="pageSize"
              (pageChange)="onPageChange($event)"
            >
              <ng-template ngbPaginationNumber let-page>
                <span
                  [ngClass]="{
                    'page-item-selected': page === service.page,
                    'page-item-unselected': page !== service.page
                  }"
                  >{{ page }}</span
                >
              </ng-template>
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
