import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, pipe, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BASE_URL_API } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PuntosCarteraService {

  constructor(private http: HttpClient) { }

  private getHeaders(): HttpHeaders {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    // Si auth_user no existe, entonces se obtiene el token de auth_app_token.
    let authUserItem;
    if(localStorage.getItem('auth_user') == null){
      authUserItem = localStorage.getItem('auth_app_token');
    } else {
      // Si auth_user existe, entonces se obtiene el token de auth_user.
      authUserItem = localStorage.getItem('auth_user');
    }
  
    // Si existe el token, se agrega al header.
    if (authUserItem) {
      const token = JSON.parse(authUserItem).token;
      if (token) {
        headers = headers.set('Authorization', `Bearer ${token}`);
      }
    }
     
    return headers;
  }

  obtenerPuntos(idUsuario: any): Observable<any> {
    return this.http.get<any>(`${BASE_URL_API}/usuarios_cartera/${idUsuario}`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }
  sumarPuntos(idUsuario: number, puntosASumar: number): Observable<any> {
    return this.http.post<any>(`${BASE_URL_API}/usuarios_cartera`, { id_usuario: idUsuario, puntos_a_sumar: puntosASumar })
      .pipe(
        catchError(this.handleError)
      );
  }

  restarPuntos(idUsuario: number, puntosARestar: number): Observable<any> {
    return this.http.post<any>(`${BASE_URL_API}/usuarios_cartera`, { id_usuario: idUsuario, puntos_a_restar: puntosARestar })
      .pipe(
        catchError(this.handleError)
      );
  }
private handleError(error: HttpErrorResponse) {
  console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
  console.log(error);
  return throwError('Algo malo ocurrió; por favor, inténtalo de nuevo más tarde.');
}
}

