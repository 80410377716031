import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ProductosService } from "src/app/shared/service/productos.service";
import { CategoriasService } from "src/app/shared/service/categorias.service";
import { ProductsRoutingModule } from "./products-routing.module";
import { DigitalListComponent } from "./digital/digital-list/digital-list.component";
import { DigitalAddComponent } from "./digital/digital-add/digital-add.component";
import { GalleryModule } from "@ks89/angular-modal-gallery";
import "hammerjs";
import "mousetrap";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { NgxDropzoneModule } from "ngx-dropzone";
// search module
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { SharedModule } from "src/app/shared/shared.module";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { EditProductComponent } from "./digital/digital-list/edit-product/edit-product.component";
import { ChatService } from "src/app/shared/service/chat.service";

@NgModule({
  declarations: [
    DigitalListComponent,
    DigitalAddComponent,
    EditProductComponent,
  ],
  imports: [
    Ng2SearchPipeModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ProductsRoutingModule,
    NgbModule,
    GalleryModule,
    CKEditorModule,
    NgxDropzoneModule,
    SharedModule,
    AngularMultiSelectModule,
  ],
  providers: [NgbActiveModal, ProductosService, CategoriasService, ChatService],
})
export class ProductsModule {}
