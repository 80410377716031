import { UsuarioHistorial } from "../model/usuario_historial.model";
import { UsuarioPanel } from "../model/usuario_panel.model";
import { Usuarios } from "../model/usuarios.model";
import { Injectable } from "@angular/core";
import { BASE_URL_API } from "./../../../environments/environment";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from 'rxjs/operators';

import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class UsuariosService {
  constructor(private http: HttpClient) {}

  selectedUser: any;

  private getHeaders(): HttpHeaders {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    // Si auth_user no existe, entonces se obtiene el token de auth_app_token.
    let authUserItem;
    if (localStorage.getItem("auth_user") == null) {
      authUserItem = localStorage.getItem("auth_app_token");
    } else {
      // Si auth_user existe, entonces se obtiene el token de auth_user.
      authUserItem = localStorage.getItem("auth_user");
    }

    // Si existe el token, se agrega al header.
    if (authUserItem) {
      const token = JSON.parse(authUserItem).token;
      if (token) {
        headers = headers.set("Authorization", `Bearer ${token}`);
      }
    }

    return headers;
  }

  getUserById(id: any): Observable<any> {
    return this.http.get(`${BASE_URL_API}/usuarios/${id}`, {
      headers: this.getHeaders(),
    });
  }

  getUserQR(id: string): Observable<Blob> {
    return this.http.get(`${BASE_URL_API}/usuarios/qr/${id}`, {
      headers: this.getHeaders(),
      responseType: "blob", // Asegúrate de configurar el responseType como 'blob'
    });
  }

  getUserPuntos(id: string): Observable<any> {
    return this.http.get(`${BASE_URL_API}/usuarios_cartera/puntos/${id}`, {
      headers: this.getHeaders(),
    });
  }

  updateUser(id: any, user: any): Observable<any> {
    return this.http.put(`${BASE_URL_API}/usuarios/${id}`, user, {
      headers: this.getHeaders(),
    })
    .pipe(catchError(this.handleError));
  }

  //Obteber los usuarios de una empresa
  getAllUsuarios(): Observable<Usuarios[]> {
    return this.http
      .get<Usuarios[]>(`${BASE_URL_API}/usuarios`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  // Obtener los usuarios de una empresa segun el id de la empresa
  getAllUsuariosEmpresa(id: any): Observable<Usuarios[]> {
    return this.http
      .get<Usuarios[]>(`${BASE_URL_API}/usuarios/empresa/${id}`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  getNumUsuariosMembresia(id_empresa:any): Observable<any> {
    return this.http.get(`${BASE_URL_API}/membresias/empresa/${id_empresa}`, {
      headers: this.getHeaders(),
    });
  }

  getAllUsuariosHistory(): Observable<UsuarioHistorial[]> {
    return this.http
      .get<UsuarioHistorial[]>(`${BASE_URL_API}/usuarios_historial`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  deleteUsuario(id: number): Observable<UsuarioPanel[]> {
    return this.http
      .delete<UsuarioPanel[]>(`${BASE_URL_API}/usuarios/${id}`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  getHistorialTransferencias(id: any): Observable<any> {
    return this.http.get(`${BASE_URL_API}/puntos_registro/${id}`, {
      headers: this.getHeaders(),
    });
  }

  register(data: any): Observable<any> {
    return this.http.post<any>(`${BASE_URL_API}/usuarios/register`, data)
      .pipe(
        tap(response => console.log('Registro exitoso', response)),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    console.error(
      `Backend returned code ${error.status}, body was: ${error.error}`
    );
    return throwError(
      "Algo malo ocurrió; por favor, inténtalo de nuevo más tarde."
    );
  }
}
