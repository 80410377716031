<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5> Añadir Cliente</h5>
                </div>
                <div class="card-body tab2-card">
                    <form [formGroup]="registerForm" (ngSubmit)="addNewUser()">
                        <div class="mb-3">
                          <label for="nombre" class="form-label">Nombre</label>
                          <input type="text" class="form-control" id="nombre" placeholder="Nombre" formControlName="nombre">
                          <div *ngIf="registerForm.get('nombre').touched && registerForm.get('nombre').invalid" class="text-danger">
                            <div *ngIf="registerForm.get('nombre').errors?.required">El nombre es requerido</div>
                            <div *ngIf="registerForm.get('nombre').errors?.minlength">El nombre debe tener al menos 3 letras</div>
                          </div>
                        </div>
                      
                        <div class="mb-3">
                          <label for="apellidos" class="form-label">Apellidos</label>
                          <input type="text" class="form-control" id="apellidos" placeholder="Apellidos" formControlName="apellidos">
                          <div *ngIf="registerForm.get('apellidos').touched && registerForm.get('apellidos').invalid" class="text-danger">
                            <div *ngIf="registerForm.get('apellidos').errors?.required">Los apellidos son requeridos</div>
                            <div *ngIf="registerForm.get('apellidos').errors?.minlength">Los apellidos deben tener al menos 3 letras</div>
                          </div>
                        </div>
                      
                        <div class="mb-3">
                          <label for="email" class="form-label">Correo Electrónico</label>
                          <input type="email" class="form-control" id="email" placeholder="Correo Electrónico" formControlName="email">
                          <div *ngIf="registerForm.get('email').touched && registerForm.get('email').invalid" class="text-danger">
                            <div *ngIf="registerForm.get('email').errors?.required">El correo electrónico es requerido</div>
                            <div *ngIf="registerForm.get('email').errors?.email">Formato de correo inválido</div>
                          </div>
                        </div>
                      
                        <div class="mb-3">
                          <label for="telefono" class="form-label">Teléfono</label>
                          <input type="text" class="form-control" id="telefono" placeholder="Teléfono" formControlName="telefono">
                          <div *ngIf="registerForm.get('telefono').touched && registerForm.get('telefono').invalid" class="text-danger">
                            <div *ngIf="registerForm.get('telefono').errors?.required">El teléfono es requerido</div>
                            <div *ngIf="registerForm.get('telefono').errors?.minlength">El teléfono debe tener al menos 9 dígitos</div>
                            <div *ngIf="registerForm.get('telefono').errors?.pattern">El teléfono solo puede contener números</div>
                          </div>
                        </div>
                      
                        <div class="mb-3">
                          <label for="ciudad" class="form-label">Ciudad</label>
                          <input type="text" class="form-control" id="ciudad" placeholder="Ciudad" formControlName="ciudad">
                          <div *ngIf="registerForm.get('ciudad').touched && registerForm.get('ciudad').invalid" class="text-danger">
                            <div *ngIf="registerForm.get('ciudad').errors?.required">La ciudad es requerida</div>
                            <div *ngIf="registerForm.get('ciudad').errors?.minlength">La ciudad debe tener al menos 3 letras</div>
                          </div>
                        </div>
                      
                        <div class="mb-3">
                          <label for="codigoPostal" class="form-label">Código Postal</label>
                          <input type="text" class="form-control" id="codigoPostal" placeholder="Código Postal" formControlName="codigoPostal">
                          <div *ngIf="registerForm.get('codigoPostal').touched && registerForm.get('codigoPostal').invalid" class="text-danger">
                            <div *ngIf="registerForm.get('codigoPostal').errors?.required">El código postal es requerido</div>
                            <div *ngIf="registerForm.get('codigoPostal').errors?.minlength">El código postal debe tener al menos 5 dígitos</div>
                            <div *ngIf="registerForm.get('codigoPostal').errors?.pattern">El código postal debe contener exactamente 5 dígitos</div>
                          </div>
                        </div>
                      
                        <div class="mb-3">
                          <label for="direccion" class="form-label">Dirección</label>
                          <input type="text" class="form-control" id="direccion" placeholder="Dirección" formControlName="direccion">
                          <div *ngIf="registerForm.get('direccion').touched && registerForm.get('direccion').invalid" class="text-danger">
                            <div *ngIf="registerForm.get('direccion').errors?.required">La dirección es requerida</div>
                            <div *ngIf="registerForm.get('direccion').errors?.minlength">La dirección debe tener al menos 3 letras</div>
                          </div>
                        </div>
                      
                        <div class="mb-3">
                          <label for="fechaNacimiento" class="form-label">Fecha de Nacimiento</label>
                          <input type="date" class="form-control" id="fechaNacimiento" formControlName="fechaNacimiento">
                          <div *ngIf="registerForm.get('fechaNacimiento').touched && registerForm.get('fechaNacimiento').invalid" class="text-danger">
                            <div *ngIf="registerForm.get('fechaNacimiento').errors?.required">La fecha de nacimiento es requerida</div>
                          </div>
                        </div>
                      
                        <div class="mb-3">
                          <label for="password" class="form-label">Contraseña</label>
                          <input type="password" class="form-control" id="password" placeholder="Contraseña" formControlName="password">
                          <div *ngIf="registerForm.get('password').touched && registerForm.get('password').invalid" class="text-danger">
                            <div *ngIf="registerForm.get('password').errors?.required">La contraseña es requerida</div>
                            <div *ngIf="registerForm.get('password').errors?.minlength">La contraseña debe tener al menos 6 caracteres</div>
                          </div>
                        </div>
                      
                        <button type="submit" class="btn btn-primary" [disabled]="registerForm.invalid">Registrarse</button>
                      </form>
                      
                    <div class="pull-right">
                        <button type="button" style="margin-top:10px;" (click)="addNewUser()"  class="btn btn-primary">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->