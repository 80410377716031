import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsuariosPanelService } from 'src/app/shared/service/usuarios_panel.service';
import Swal from 'sweetalert2';
import { UsuariosService } from 'src/app/shared/service/usuarios.service';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss']
})
export class CreateClientComponent {
  public registerForm: FormGroup;
  public permissionForm: FormGroup;
  public active = 1;
  successMessage: any;
  errorMessage: any;
  

  constructor
  (
    private router: Router,
    private fb: FormBuilder,
    private usuariosPanelService: UsuariosPanelService,
    private usuariosService: UsuariosService
  ) 
  { }

  ngOnInit() {
    this.createRegisterForm()
  }


  createRegisterForm() {
    this.registerForm = this.fb.group({
      nombre: ['',[Validators.required, Validators.minLength(3)]],
      apellidos: ['',[Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      telefono: ['', [Validators.required, Validators.minLength(9), Validators.pattern("^[0-9]*$")]],
      ciudad: ['', [Validators.required, Validators.minLength(3)]],
      codigoPostal: ['', [Validators.required, Validators.minLength(5), Validators.pattern("^[0-9]{5}$")]],
      direccion: ['',[Validators.required, Validators.minLength(3)]],
      fechaNacimiento: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  addNewUser(): void {
    if (this.registerForm.valid) {
      let usuario = {
        nombre: this.registerForm.get('nombre')?.value,
        apellidos: this.registerForm.get('apellidos')?.value,
        email: this.registerForm.get('email')?.value,
        pass_hash: this.registerForm.get('password')?.value,
        telefono: this.registerForm.get('telefono')?.value,
        ciudad:this.registerForm.get('ciudad')?.value,
        cp: this.registerForm.get('codigoPostal')?.value,
        direccion: this.registerForm.get('direccion')?.value,
        fecha_nacimiento: this.registerForm.get('fechaNacimiento')?.value,
        id_empresa: 1 
      }
      console.log(usuario);
      this.usuariosService.register(usuario).subscribe({
        next: (response) => {
          console.log('Usuario añadido con éxito', response);
          this.successMessage = "Usuario registrado con éxito. ¡Bienvenido!";
          this.errorMessage = ''; // Limpia el mensaje de error si estaba visible antes
          this.router.navigate(['sign-in']); // Navega a 'home' después de un registro exitoso
        },
        error: (errorMessage) => {
          console.error('Error al añadir usuario:', errorMessage);
          this.errorMessage = errorMessage; // Usa el mensaje de error proporcionado por el servicio
          this.successMessage = ''; // Limpia el mensaje de éxito si estaba visible
        }
      });
    } else {
      this.errorMessage = 'Por favor, asegúrate de que todos los campos estén llenos y sean válidos.';
      this.successMessage = '';
    }
  }

}
