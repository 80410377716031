<div class="container-fluid">
  <div class="row product-adding">
    <div class="col-xl">
      <div class="card">
        <div class="card-body">
          <div class="digital-add needs-validation">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label pt-0"
                    ><span>*</span> Detalles</label
                  >
                  <textarea
                    rows="5"
                    cols="12"
                    class="form-control"
                    required
                    [(ngModel)]="detalles"
                  ></textarea>
                </div>
                <label for="imageURL">URL de la imagen:</label>
                <div class="card-body p-0">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="imageURL"
                      [(ngModel)]="imagen"
                      (change)="onUrlChange($event)"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-xl-3 col-md-4">
                    <label><span>*</span> Fecha inicio</label>
                  </div>
                  <div class="col-md-7">
                    <div class="input-group input-grp-p">
                      <input
                        class="form-control digits"
                        ngbDatepicker
                        #f="ngbDatepicker"
                        [(ngModel)]="fecha_inicio"
                        readonly
                      />
                      <div class="input-group-append">
                        <button
                          class="btn calendar"
                          (click)="f.toggle()"
                          type="button"
                        >
                          <i class="fa fa-calendar"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label"
                    ><span>*</span> Puntos necesarios</label
                  >
                  <input
                    class="form-control"
                    type="number"
                    required
                    [(ngModel)]="puntos_necesarios"
                  />
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <div class="checkbox checkbox-primary">
                      <input
                        id="checkbox-primary-2"
                        type="checkbox"
                        [(ngModel)]="activo"
                        data-original-title=""
                        title=""
                      />
                      <label for="checkbox-primary-2">Activar cupón</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            class="btn btn-primary me-1 mb-3"
            style="float: left"
            (click)="updateCupon()"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
